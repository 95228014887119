<template>
  <div class="top-link">
    <!-- <div class="left">
      <Dropdown placement="bottom-start" class="my-down">
        <div class="item" @click="tap">
          <img src="@assets/img/icon-lb.png" />展品类别
        </div>
        <DropdownMenu slot="list">
          <div class="list"
            :class="isMore ? 'moreList' : ''"
            ref="moreList">
            <div class="content" ref="moreList"
              v-if="cateList && cateList.length">
              <div class="list-item"
                v-for="(item, index) in cateList"
                :key="'ca-' + index">
                <div class="title"
                  @click="setCategories(item)">
                  {{ item.name }}
                </div>
                <template v-if="item.childrens">
                  <span class="brand"
                    v-for="(c, idx) in item.childrens"
                    :key="'ca-child-' + index + idx"
                    @click="setCategories(c)">{{ c.name }}</span>
                </template>
              </div>
            </div>
            <div class="top-more" @click.stop="topMore"
              v-if="isMore">
              <Icon type="ios-arrow-down" color="#c7c7c7" />
            </div>
            <div v-else-if="!cateList.length"
              class="u-empty">{{ $t('lang.No_data') }}</div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </div> -->
    <div class="right">
      <div class="item" :class="{ active: path.indexOf('newsDetails') == -1 && path.indexOf(arr[0].link) !== -1 }" @click="linkTo(arr[0])">
        <span>{{ arr[0].title }}</span>
      </div>

      <div
        class="item"
        :class="{ active: path.indexOf('newsDetails') == -1 && path.indexOf(arr[1].link) !== -1 }"
        @click="linkTo(arr[1])"
        v-if="exhibitionConfig && exhibitionConfig.feature && exhibitionConfig.feature.enabledSupplyDemand"
      >
        <span>{{ arr[1].title }}</span>
      </div>

      <div class="item" :class="{ active: path.indexOf('newsDetails') == -1 && path.indexOf(arr[2].link) !== -1 }" @click="linkTo(arr[2])">
        <span>{{ arr[2].title }}</span>
      </div>

      <div class="item" :class="{ active: path.indexOf('newsDetails') == -1 && path.indexOf(arr[3].link) !== -1 }" @click="linkTo(arr[3])">
        <span>{{ arr[3].title }}</span>
      </div>

      <div class="item" :class="{ active: path.indexOf('newsDetails') == -1 && path.indexOf(arr[4].link) !== -1 }" @click="linkTo(arr[4])">
        <span>{{ arr[4].title }}</span>
      </div>

      <div class="item" :class="{ active: path.indexOf('newsDetails') == -1 && path.indexOf(arr[5].link) !== -1 }" @click="linkTo(arr[5])">
        <span>{{ arr[5].title }}</span>
      </div>

      <div
        class="item"
        :class="{ active: path.indexOf('newsDetails') == -1 && path.indexOf(arr[6].link) !== -1 }"
        @click="linkTo(arr[6])"
        v-if="exhibitionConfig && exhibitionConfig.feature && exhibitionConfig.feature.enabledArticle"
      >
        <span>{{ arr[6].title }}</span>
      </div>

      <div class="item" :class="{ active: path.indexOf('newsDetails') == -1 && path.indexOf(arr[7].link) !== -1 }" @click="linkTo(arr[7])">
        <span>{{ arr[7].title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "top-link",
  data() {
    return {
      exhibitionConfig: null,
      show: false,
      cateList: [],
      isMore: true,
    };
  },
  computed: {
    ...mapGetters({
      enabledBmm: "getEnabledBmm",
      selectCategories: "getSelectCategories",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
    }),
    path() {
      return this.$route.path;
    },
    arr() {
      return [
        { title: "Home", link: "/home/index" },
        { title: "Demand", link: "/home/demand" },
        { title: "Exhibitors", link: "/home/exhibitor" },
        { title: "Products", link: "/home/product" },
        { title: "Activities", link: "/home/activity" },
        { title: "Floorplan", link: "/home/tour" },
        { title: "News", link: "/home/news" },
        { title: "About", link: "/home/about" },
      ];
    },
  },
  created() {
    this.init();
  },
  mounted() {
    // this.$nextTick(() => {
    //   let height = this.$refs.moreList.offsetHeight;
    //   console.log(this.$refs.moreList, height, "moreList");
    // });
  },
  methods: {
    ...mapMutations({
      setSelectCategories: "setSelectCategories",
    }),
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    linkTo(item) {
      this.$store.commit("setExhibitorPage", 0);
      this.$store.commit("setDemandPage", 0);
      this.$store.commit("setProductPage", 0);
      this.$store.commit("setActivityPage", 0);
      // this.$store.commit("setDemandType", "Purchase");
      this.$store.commit("setDemandType", "Supply");
      this.$store.commit("setCateArrVuex", []);
      this.$store.commit("setRegionArrVuex", []);
      this.$store.commit("setRegionArrNameVuex", []);
      if (item.link === "/home/tour") {
        if (this.enabledBmm) {
          this.$router.push(`${item.link}/${this.exhibitionId}`);
        } else {
          this.$Message.info("The function is not open!");
        }
      } else {
        this.$router.push(`${item.link}/${this.exhibitionId}`);
      }
    },
    tap() {
      this.show = !this.show;
      // this.$nextTick(() => {
      //   let height = this.$refs.moreList.offsetHeight;
      //   if (height > 476) {
      //     this.isMore = true;
      //   } else {
      //     this.isMore = false;
      //   }
      // });
    },
    topMore() {
      this.isMore = false;
    },
    //获取数组  传一个数组json 遍历其下 id 于 name isLists 是否获取其list
    getList(arrJson) {
      let classArr = [];
      arrJson.forEach((el) => {
        classArr.push({
          id: el.id,
          name: el.name,
        });
      });
      return classArr;
    },
    getRegionList(arrJson, isLists = true) {
      let classArr = [];
      if (isLists) {
        arrJson.forEach((el) => {
          classArr.push({
            id: el.id,
            name: el.name,
            expand: true,
            children: this.getList(el.lists),
          });
        });
      } else {
        arrJson.forEach((el) => {
          classArr.push({
            id: el.id,
            name: el.name,
            expand: true,
          });
        });
      }
      return classArr;
    },
    async init() {
      let categoriesGql = `
          query($hostId:Guid!){
            categories(hostId:$hostId){
              childrens{
                  createAt
                  icon
                  id
                  isDeleted
                  level
                  name
                  nameEn
                  parentId
                  shortName
                  shortNameEn
                  showIndex
                  updateAt
              }
              createAt
              icon
              id
              isDeleted
              banners
              level
              name
              nameEn
              parentId
              shortName
              shortNameEn
              showIndex
              updateAt
            }
          }`;
      let opt = {
        query: categoriesGql,
        variables: {
          hostId: this.exhibitionId,
        },
      };
      let res = await this.graphqlPost(opt);
      let data = res.data.categories;
      this.cateList = data;
      this.getExConfig();
    },
    async getExConfig() {
      let query = `query exhibitionConfig($exhibitionId:Guid!){
                  exhibitionConfig(exhibitionId:$exhibitionId){
                    display{            
                      boothLink         
                      exhibitorWapLogo  
                      exhibitorWebLogo
                      showExhibitorCount 
                      showViewCount       
                      enabledExhibitorLimitNumber
                      exhibitorLimitNumber
                      homeActivity{
                        col
                        row
                      }
                      homeExhibitorLogo{
                        col
                        row
                      }
                      showExhibitorCount
                      showViewCount
                    }
                    maxExhibitorNumber
                    visitorReg{      
                      notice          
                      regLink         
                      regSuccessedNotice  
                    }
                    feature{
                      enabledDataStatistics
                      enabledArticle
                      enabledExhibitionList
                      enabledBrandExhibitor
                      enabledSupplyDemand
                      enabledSpecialLive
                      enabledConcurrentActivity
                      enabledNearbyExhibitor
                      enabledRecommendForYou
                      enabledBmm
                    },
                    invitation{
                      backgroundColor 
                      backgroundUrl
                      topToContent
                    }
                    enabledAutoAuditActivity
                    enabledAutoAuditExhibitor
                    enabledAutoAuditLive
                    enabledAutoAuditProduct
                    enabledAutoAuditSample
                    enabledAutoAuditSupplyDemand
                    enabledAutoAuditTheme
                  }
                }`;
      let opt = {
        query: query,
        variables: {
          exhibitionId: this.exhibitionId,
        },
      };
      let res = await this.graphqlPost(opt);
      if (res.data.exhibitionConfig) {
        this.exhibitionConfig = res.data.exhibitionConfig;
      }
      console.log(this.exhibitionConfig, this.enabledBmm, "------------");
    },
    setCategories(e) {
      this.setSelectCategories(e);
      this.show = false;
      this.$router.push(`/home/product/${this.exhibitionId}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.top-more {
  width: 100%;
  height: 17px;
  background-color: #e7f4ff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4d4d4d;
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
.list {
  width: 250px;
  background-color: #fff;
  position: relative;
  .content {
    padding: 18px 20px 0 20px;
  }
  .list-item {
    margin-bottom: 9px;
    cursor: pointer;

    .title {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      margin-bottom: 12px;
      &:hover {
        @include font_color(#1890ff);
      }
    }
    .brand {
      font-size: 12px;
      color: #666;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 12px;
      &:hover {
        @include font_color(#1890ff);
      }
    }
  }
}
.moreList {
  max-height: 477px;
  overflow: hidden;
}
.top-link {
  font-size: 16px;
  display: flex;
  width: 1200px;
  margin: 0 auto;
  .left {
    position: relative;
    .item {
      width: 160px;
      height: 42px;
      margin-right: 5px;
      @include background_color(#1890ff);
      color: #fff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px 5px 0px 0px;
      cursor: pointer;
      img {
        padding-right: 10px;
      }
    }
  }
  .right {
    .item {
      color: #333;
      line-height: 42px;
      display: inline-block;
      padding: 0 43px;
      cursor: pointer;
      border-radius: 5px 5px 0px 0px;
      transition: all 0.3s;
      &.active {
        color: #fff !important;
        @include background_color(#1890ff);
      }
      &:hover {
        @include font_color(#1890ff);
      }
    }
  }
}

.my-down {
  ::v-deep {
    .ivu-select-dropdown {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
