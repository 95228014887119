<template>
  <div class="side">
    <template v-if="userInfo">
      <div class="item" :class="{ talk_open: talkShow }" @click="talkList()" v-if="isRegOk">
        <i class="icon iconfont icon-ziyuan"></i>
        <div class="txt">Chat</div>
        <div class="msg_count" v-if="msgCount">{{ msgCount > 99 ? "99+" : msgCount }}</div>
      </div>
    </template>
    <div class="item" @click="goWebsite()" v-if="exhibitionInfo.website">
      <i class="icon iconfont icon-demo17"></i>
      <div class="txt">Show web</div>
    </div>
    <div class="item" @click="goCallme()">
      <i class="icon iconfont icon-kefu"></i>
      <div class="txt">{{ $t("lang.Contact") }}</div>
    </div>
    <Dropdown placement="left" trigger="click">
      <div class="item">
        <i class="icon iconfont icon-fenxiang2"></i>
        <div class="txt">{{ $t("lang.Share") }}</div>
      </div>
      <DropdownMenu slot="list">
        <DropdownItem @click.native="share('linkedin')">
          <i class="icon icon-5 iconfont icon-lingying"></i>
          LinkedIn
        </DropdownItem>
        <DropdownItem @click.native="share('facebook')">
          <i class="icon icon-5 iconfont icon-facebook"></i>
          Facebook
        </DropdownItem>
        <DropdownItem @click.native="share('twitter')">
          <i class="icon icon-5 iconfont icon-twitter1"></i>
          Twitter
        </DropdownItem>
        <!-- <DropdownItem @click.native="share('web')">
          <i class="icon icon-1 iconfont icon-weibo1"></i>
          新浪微博
        </DropdownItem>
        <DropdownItem @click.native="share('qzone')">
          <i class="icon icon-2 iconfont icon-QQkongjian31"></i>
          QQ空间
        </DropdownItem>
        <DropdownItem @click.native="share('qq')">
          <i class="icon icon-3 iconfont icon-qq"></i>
          QQ好友
        </DropdownItem>
        <Poptip trigger="hover" content="content" placement="left-start" width="190">
          <DropdownItem>
            <i class="icon icon-4 iconfont icon-weixin2"></i>
            微信
          </DropdownItem>
          <div slot="content" class="my-show">
            <div id="qrcodeShare" class="qrcode" ref="qrCodeUrl"></div>
            <p
              style="
                text-align: center;
                margin-top:10px;
              "
            >
              {{ $t("lang.Scan_QR_code") }}
            </p>
          </div>
        </Poptip>-->
      </DropdownMenu>
    </Dropdown>
    <div class="item" @click="top" v-if="$i18n.locale === 'zh'">
      <i class="icon iconfont icon-top"></i>
      <div class="txt">{{ $t("lang.Top") }}</div>
    </div>
    <div class="item" @click="top" v-else>
      <i class="icon iconfont icon-top" style="padding-top: 13px;"></i>
    </div>
    <div>
      <!-- <shareMobileWx ref="showMobile" v-if="exhibitionId"></shareMobileWx> -->
    </div>
    <talkWrap v-if="talkShow"></talkWrap>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import QRCode from "qrcodejs2";
import talkWrap from "../chat-wrap/index.vue";
export default {
  name: "gSide",
  data() {
    return {
      value1: false,
      qrcodUrl: "",
      talkShow: false,
      time: null,
      msgCount: 0,
    };
  },
  components: {
    talkWrap,
  },
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
      getShare: "getShare",
      lang: "getLang",
    }),
    isRegOk() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole && this.userInfo.inMember.userRole != "VISITOR" && this.userInfo.inMember.userRole != "Visitor";
    },
    isServer() {
      let ser = false;
      if (this.userInfo && this.userInfo.inMember && this.userInfo.inMember.companyUsers && this.userInfo.inMember.companyUsers.isAppointment) {
        ser = true;
      }
      return ser;
    },
    userMsgQuery() {
      return `
        query{
          agoraQuery{
            userUnreadNumber(exhibitionId:"${this.exhibitionId}",publisherId:"${this.userInfo.userId}")
          }
        }
      `;
    },
    serviceMsgQuery() {
      return `
        query{
          agoraQuery{
            exhibitorUnreadNumber(exhibitorId:"${this.userInfo.inMember.exhibitorId}",publisherId:"${this.userInfo.inMember.companyUsers.id}")
          }
        }
      `;
    },
  },
  created() {
    console.log(this.exhibitionInfo, "this.exhibitionInfo分享");
    if (this.getShare.title) {
      // 没有分享配置
      let share = {
        url: location.href,
        title: this.exhibitionInfo.name,
        desc: this.exhibitionInfo.name,
        summary: this.exhibitionInfo.remark,
        pics: this.exhibitionInfo.logo,
      };
      this.$store.commit("setShare", share);
    }
    if (this.isRegOk) {
      this.goInit();
    }
  },
  beforeDestroy() {
    if (this.time) {
      clearInterval(this.time);
      this.time = null;
    }
  },
  mounted() {
    this.$Bus.$off("talkClose");
    this.$Bus.$on("talkClose", () => {
      this.talkShow = false;
    });
  },
  methods: {
    ...mapActions({
      unverifiedDialog: "unverifiedDialog",
      graphqlPostByZXS: "graphqlPostByZXS",
    }),
    goInit() {
      this.init();
      this.time = setInterval(() => {
        this.init();
      }, 30000);
    },
    top() {
      // var timer = setInterval(function () {
      //   let osTop =
      //     document.documentElement.scrollTop || document.body.scrollTop;
      //   let ispeed = Math.floor(-osTop / 5);
      //   document.documentElement.scrollTop = document.body.scrollTop =
      //     osTop + ispeed;
      //   this.isTop = true;
      //   if (osTop === 0) {
      //     clearInterval(timer);
      //   }
      // }, 30);
      $("html , body").animate({ scrollTop: 0 }, "slow");
    },
    async init() {
      let opt = {
        query: this.userMsgQuery,
        variables: {},
      };
      let data = await this.graphqlPostByZXS(opt);
      console.log(data, "datatata用户消息");
      let count = data.data.agoraQuery.userUnreadNumber || 0;
      if (this.isServer) {
        let opt2 = {
          query: this.serviceMsgQuery,
          variables: {},
        };
        let data2 = await this.graphqlPostByZXS(opt2);
        console.log(data2, "data2");
        count += data2.data.agoraQuery.exhibitorUnreadNumber || 0;
      }
      this.msgCount = count;
      //300000
      // console.log(count,'count');
    },
    goWebsite() {
      if (this.exhibitionInfo && this.exhibitionInfo.website) {
        window.open(this.exhibitionInfo.website);
      }
    },
    goCallme() {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      this.$router.push(`/user/customer?exhibitionId=${this.exhibitionId}`);
    },
    getQrCode() {
      console.log(this.qrcodUrl, "this.qrcodUrl");
      // this.url = location.href;
      let qrcode = new QRCode("qrcodeShare", {
        width: 130,
        height: 130,
        text: this.qrcodUrl, // 二维码内容
        correctLevel: 3,
      });
    },
    share(type) {
      let title = encodeURIComponent(this.getShare.title);
      let url = encodeURIComponent(location.href);
      let picurl = encodeURIComponent(this.getShare.pics);
      let summary = encodeURIComponent(this.getShare.summary);
      let desc = encodeURIComponent(this.getShare.desc);
      let shareUrl = "";
      switch (type) {
        case "web":
          encodeURIComponent;
          shareUrl = `https://service.weibo.com/share/share.php?url=${url}&title=${title}&&pic=${picurl}&&pic=${picurl}`;
          console.log(shareUrl, "微博分享地址");
          window.open(shareUrl, "newwindow", "height=400,width=600,top=100,left=100");
          break;
        case "qzone":
          shareUrl = `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?to=qzone&url=${url}&title=${title}&summary=${summary}`;
          console.log(shareUrl, "qzone分享地址");
          window.open(shareUrl, "newwindow", "height=400,width=600,top=100,left=100");
          break;
        case "qq":
          let share = `https://connect.qq.com/widget/shareqq/index.html?url=${url}&desc=${desc}&summary=&title=${title}&pics=${picurl}`;
          shareUrl = `https://connect.qq.com/widget/shareqq/index.html?url=${url}&desc=${desc}&summary=${summary}&title=${title}&&pics=${picurl}`;
          console.log(shareUrl, "QQ分享地址");
          window.open(share, "newwindow", "height=400,width=600,top=100,left=100");
          break;
        case "wx":
          this.$refs.showMobile.open();
          break;
        case "linkedin":
          shareUrl = `https://www.linkedin.com/shareArticle?mini=true&source=str&title=${title}&url=${url}`;
          console.log(shareUrl, "linkedin分享地址");
          window.open(shareUrl, "newwindow", "height=400,width=600,top=100,left=100");
          break;
        case "facebook":
          shareUrl = `http://www.facebook.com/sharer.php?u=${url}&t=${title}`;
          console.log(shareUrl, "facebook分享地址");
          window.open(shareUrl, "newwindow", "height=400,width=600,top=100,left=100");
          break;
        case "twitter":
          shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;
          console.log(shareUrl, "twitter分享地址");
          window.open(shareUrl, "newwindow", "height=400,width=600,top=100,left=100");
          break;
      }
    },
    talkList() {
      if(this.userInfo&&this.userInfo.inMember.isBlacklist){
        this.$Message.warning('You do not have access');
        return 
      } 
      this.talkShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
::v-deep {
  .ivu-dropdown {
    position: relative;
  }
  .ivu-select-dropdown {
    left: -130px !important;
    top: -4px !important;
  }
  .ivu-back-top {
    bottom: 0 !important;
    right: 0 !important;
    position: relative;
    display: block;
  }
  .ivu-dropdown-item {
    border-bottom: dashed 1px #d9d9d9;
    display: flex;
    align-items: center;
    .icon {
      color: #bfbfbf;
      font-size: 20px;
      margin-right: 8px;
    }
  }
  .ivu-dropdown-item:last-child {
    border: none;
  }
  .ivu-dropdown-item:hover {
    background: #fff;
    .icon-1 {
      color: #e44c3f;
    }
    .icon-2 {
      color: #fab619;
    }
    .icon-3 {
      color: #17abe3;
    }
    .icon-4 {
      color: #49bd38;
    }
    .icon-5 {
      color: #0077b5;
    }
  }
  i {
    color: #333;
    padding: 0;
    font-size: 18px;
  }
  .icon-top {
    font-size: 24px;
  }
  .ivu-poptip-body {
    padding: 14px;
    .my-show {
      img {
        margin: 0 auto;
      }
    }
  }
}

.side {
  position: fixed;
  top: 200px;
  right: 20px;
  width: 60px;
  height: 200px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  z-index: 950;
  font-size: 12px;
  &.tour_wrap {
    top: 50%;
  }
  .item {
    width: 60px;
    height: 60px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    text-align: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    color: #333;
    background-color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
    &.talk_open {
      @include font_color(#1890ff);
      i {
        @include font_color(#1890ff);
      }
    }
    &:hover {
      color: #fff;
      &.talk_open {
        color: #fff;
        i {
          color: #fff;
        }
      }
      @include background_color(#1890ff);
      i {
        color: #fff;
      }
    }
    .txt {
      width: 100%;
    }

    .msg_count {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #d34242;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -10px;
      top: -10px;
      color: #fff;
    }
  }
}
</style>
