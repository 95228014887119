<template>
  <div class="chat_wrap_div" :class="{isUser:isUser}">
    <div class="chat_list">
      <div class="list_top" v-if="isServer">
        <div class="top_ser">
          <Button shape="circle" :type="ident == 'service' ? 'primary' : 'default'" @click="changeIdent('service')">My customer</Button>
          <Button shape="circle" :type="ident == 'user' ? 'primary' : 'default'" @click="changeIdent('user')">Exhibitors</Button>
        </div>
      </div>
      <div class="list_top" v-else>Chat（{{ list.length }}）</div>
      <div class="list_wrap">
        <div class="list_scroll" ref="listScroll">
          <div class="scroll_wrap" id="scroll_wrap">
            <template v-if="isServer && ident == 'service'">
              <!-- 我是客服 -->
              <div class="list_item" @click="changeExorId(item, 'service')" :class="{ is_active: item.userId == actSerUserId }" v-for="(item, index) in list" :key="index">
                <div class="img_div">
                  <span class="new" v-if="item.unreadNumberToPublisher > 0">{{ item.unreadNumberToPublisher }}</span>
                  <img :src="item.member.avatarUrl" v-if="item.member && item.member.avatarUrl" />
                  <img v-else src="@/assets/img/empty.png" alt="" />
                </div>
                <div class="item_info">
                  <div class="info_top">
                    <!-- <span>慕渊智能科技</span> -->
                    <p>{{ item.member ? item.member.name || item.member.nickName : "" }}</p>
                    <span class="info_time">{{ item.lastDateStr }}</span>
                  </div>
                  <template v-if="item.lastMessage">
                    <p v-if="item.lastMessage.messageType == 'text'">{{ item.lastMessage.message.content }}</p>
                    <p v-else-if="item.lastMessage.messageType == 'url'">[Video chat room]</p>
                    <p v-else-if="item.lastMessage.messageType == 'request'">The request has been sent. Please wait a moment...</p>
                  </template>
                  <p v-else></p>
                </div>
              </div>
            </template>
            <template v-else>
              <!-- 我是客户 -->
              <div class="list_item" @click="changeExorId(item)" :class="{ is_active: item.exhibitorId == actExhibitorId }" v-for="(item, index) in list" :key="index">
                <div class="img_div">
                  <span class="new" v-if="item.unreadNumberToPublisher > 0">{{ item.unreadNumberToPublisher }}</span>
                  <img :src="item.exhibitor.company.logo" alt="" />
                </div>
                <div class="item_info">
                  <div class="info_top">
                    <!-- <span>慕渊智能科技</span> -->
                    <p>{{ item.exhibitor.company.nameEn }}</p>
                    <span class="info_time">{{ item.lastDateStr }}</span>
                  </div>
                  <template v-if="item.lastMessage">
                    <p v-if="item.lastMessage.messageType == 'text'">{{ item.lastMessage.message.content }}</p>
                    <p v-else-if="item.lastMessage.messageType == 'url'">[Video chat room]</p>
                    <p v-else-if="item.lastMessage.messageType == 'request'">Apply for video chat</p>
                  </template>
                  <p v-else></p>
                </div>
              </div>
            </template>
            <div class="loading_div">
              <Spin fix v-if="isPullUpLoad">
                <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
                <div>Loading</div>
              </Spin>
              <p v-else-if="finished">It's the end~</p>
              <p v-else-if="!list.length">No data</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <chatTalk :exhibitorId="actSerExhibitorId" :userId="actSerUserId" v-if="isServer && ident == 'service'"></chatTalk>
    <chatTalkUser :exhibitorId="actExhibitorId" v-else></chatTalkUser>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import chatTalkUser from "../chat-talk-user/index.vue";
import chatTalk from "../chat-talk/index.vue";
import Bscroll from "better-scroll";
import dateString from "../../assets/js/dateString";
export default {
  name: "chat-wrap",
  props:{
    isUser:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      value1: false,
      count: 0,
      isPullUpLoad: false,
      scroll: null,
      pageIndex: 1,
      pageSize: 20,
      finished: false,
      list: [],
      actExhibitorId: "",
      time: null,
      ident: "service",
      actSerUserId: "",
      actSerExhibitorId: "",
    };
  },
  components: {
    chatTalkUser,
    chatTalk,
  },
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
      token: "getToken",
    }),
    isRegOk() {
      return this.userInfo && this.userInfo.inMember && this.userInfo.inMember.userRole && this.userInfo.inMember.userRole != "VISITOR" && this.userInfo.inMember.userRole != "Visitor";
    },
    isServer() {
      let ser = false;
      if (this.userInfo && this.userInfo.inMember && this.userInfo.inMember.companyUsers && this.userInfo.inMember.companyUsers.isAppointment) {
        ser = true;
      }
      return ser;
    },
  },
  created() {
    this.getUser();
    // this.init();
  },
  mounted() {
    this.initSwiper();
  },
  beforeDestroy() {
    if (this.time) {
      clearInterval(this.time);
      this.time = null;
    }
  },
  methods: {
    ...mapActions({
      unverifiedDialog: "unverifiedDialog",
      graphqlPostByZXS: "graphqlPostByZXS",
      graphqlPost: "graphqlPost",
    }),
    ...mapMutations({
      setUser: "setUser",
    }),
    async getUser() {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company 
              country
              county
              createAt
              deleteAt
              department
              email
              emailConfirmed
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){
                business
                category
                id
                memberId
                originId
                originLang
                originRemark
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                exhibitorId
                isBlacklist
                companyUsers{
                  id
                  isAppointment
                  company {
                    nameEn
                  }
                }
              }
              introduction
              isDeleted
              jobTitle
              jobTitleEn
              name
              nameEn
              nickName
              phone
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          }
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setUser(data.data.memberQuery.current);
        console.log(this.userInfo, "userInfo");
        this.init();
      }
    },
    changeIdent(type) {
      if (this.ident == type) {
        return;
      }
      this.ident = type;
      if (this.time) {
        clearInterval(this.time);
        this.time = null;
      }
      this.init();
    },
    init() {
      this.getList();
      this.time = setInterval(() => {
        if (this.list.length) {
          this.pageIndex = 1;
          this.getList();
        }
        // console.log('调用');
      }, 3000);
    },
    listBottom() {
      console.log("触发下拉");
      if (this.finished) {
        this.scroll.finishPullUp();
        return;
      }
      // console.log('走了吗')
      this.pageIndex += 1;
      this.isPullUpLoad = true;
      this.getList();
    },
    initSwiper() {
      this.scroll = new Bscroll(this.$refs.listScroll, {
        scrollY: true,
        click: true,
        probeType: 3,
        mouseWheel: true,
        pullUpLoad: true,
        bounce: {
          bottom: true,
        },
        // scrollbar: {
        //   fade: false,
        // },
      });
      let that = this;
      this.scroll.on("pullingUp", this.listBottom);
    },
    async getList() {
      let publisherId = this.isServer && this.ident == "service" ? this.userInfo.inMember.companyUsers.id : this.userInfo.userId;
      // console.log(this.userInfo.inMember.companyUsers.id,'客服id')
      // console.log(this.userInfo.userId,'用户')
      // console.log(publisherId,'发布人id')
      let query = `
        query($query:QueryInput!){
          agoraQuery{
            query(query:$query){
                hasNextPage
                pageIndex
                totalCount
                items {
                  createAt
                  exhibitorId
                  exhibitor{
                    company{
                      name
                      logo
                      nameEn
                    }
                  }
                  member{
                    avatarUrl
                    name
                    nickName
                  }
                  id
                  lastMessage{
                    messageType
                    message
                    state
                  }
                  lastMessageAt
                  unreadNumberToPublisher(publisherId:"${publisherId}")
                  userId
                }
              }
            }
        }
      `;
      let where = {
        EqualA_de: {
          n: "isDeleted",
          v: 0,
        },
        EqualB: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
        Equal: {
          n: "userId",
          v: this.userInfo.userId,
        },
      };
      let order = [
        {
          N: "lastMessageAt",
          v: 0,
        },
      ];
      if (this.isServer && this.ident == "service") {
        where.Equal = {
          n: "exhibitorId",
          v: this.userInfo.inMember.exhibitorId,
        };
      }
      let opt = {
        query: query,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPostByZXS(opt);
      // console.log(data,'datatata')
      let res = JSON.parse(JSON.stringify(data.data.agoraQuery.query));
      res.items.forEach((e) => {
        e.lastDateStr = dateString.getDataString(moment(e.lastMessageAt).format("x"), false);
        if (e.lastMessage) {
          e.lastMessage.message = JSON.parse(e.lastMessage.message);
        }
      });
      if (this.pageIndex == 1) {
        this.list = res.items;
      } else {
        this.list = [...new Set([...this.list, ...res.items])];
      }
      console.log(this.list, "ssss");
      if (!res.hasNextPage) {
        this.finished = true;
      }
      this.isPullUpLoad = false;
      this.scroll.finishPullUp();
      this.$nextTick(() => {
        this.scroll.refresh();
      });
    },
    changeExorId(e, type = "user") {
      if (type == "user") {
        this.actExhibitorId = e.exhibitorId;
      } else {
        this.actSerUserId = e.userId;
        this.actSerExhibitorId = e.exhibitorId;
      }

      // console.log(e,'eee');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.chat_wrap_div {
  width: 690px;
  height: 500px;
  position: absolute;
  top: 0;
  right: 100px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  z-index: 999;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  &.isUser{
    position: fixed;
    top: 200px;
    right:120px
  }
  div {
    box-sizing: border-box;
  }
  .chat_list {
    width: 290px;
    height: 100%;
    position: relative;
    z-index: 3;
    .list_top {
      height: 62px;
      border-bottom: 1px solid #f2f2f2;
      padding: 0 14px;
      display: flex;
      align-items: center;
      color: #333;
      font-size: 16px;
      .top_ser {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
      }
    }
    .list_wrap {
      height: 438px;
      &::v-deep .ivu-scroll-wrapper {
        .ivu-scroll-container {
          &::-webkit-scrollbar {
            width: 6px;
            // opacity: 0;
          }
          &::-webkit-scrollbar-track {
            background: rgba(#000, 0);
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba($color: #000000, $alpha: 0.1);
            border-radius: 3px;
          }
          .ivu-scroll-loader {
            margin-top: 20px;
            padding-bottom: 0 !important;
            .ivu-scroll-spinner {
              .ivu-spin {
                .ivu-spin-main {
                  .ivu-spin-text {
                    .ivu-scroll-loader-text {
                      @include font_color(#1890ff);
                    }
                  }
                }
              }
            }
            &:nth-child(1) {
              display: none;
            }
          }
        }
      }
      .list_scroll {
        width: 100%;
        height: 438px;
        position: relative;
        overflow: hidden;
        .scroll_wrap {
          // height: 100%;
          .list_item {
            padding: 15px;
            background: rgba(0, 0, 0, 0);
            display: flex;
            // align-items: center;
            position: relative;
            cursor: context-menu;
            user-select: none;
            &:hover {
              background: rgba($color: #f5f8fa, $alpha: 0.5);
            }
            &::before {
              content: "";
              position: absolute;
              display: block;
              background: #f2f2f2;
              left: 67px;
              right: 0;
              height: 1px;
              bottom: 0;
            }
            &.is_active {
              background: #f5f8fa;
              &::after {
                content: "";
                display: block;
                width: 4px;
                height: 100%;
                @include background_color(#1890ff);
                position: absolute;
                left: 0;
                top: 0;
              }
            }
            .img_div {
              flex-shrink: 0;
              width: 42px;
              height: 42px;
              margin-right: 10px;
              position: relative;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              .new {
                position: absolute;
                right: -3px;
                top: -3px;
                padding: 2px 5px;
                font-size: 12px;
                min-width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                background: #ff5e00;
                border-radius: 7px;
              }
              img {
                width: 42px;
                height: 42px;
                display: block;
                object-fit: cover;
                border-radius: 50%;
              }
            }
            .item_info {
              width: calc(100% - 52px);
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .info_top {
                display: flex;
                align-items: center;
                .info_time {
                  flex-shrink: 0;
                  margin-left: auto;
                  color: #999;
                  font-size: 12px;
                }
                p {
                  font-size: 14px;
                  color: #333;
                  span {
                    color: #999;
                    font-size: 12px;
                    margin-left: 5px;
                  }
                }
              }
              > p {
                color: #666;
                font-size: 12px;
              }
              p {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
        .loading_div {
          margin-top: 10px;
          width: 100%;
          height: 40px;
          position: relative;
          &::v-deep .ivu-spin {
            @include font_color(#1890ff);
            .ivu-spin-main {
              .ivu-spin-text {
                i {
                  @include font_color(#1890ff);
                }
              }
            }
          }
          p {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
