<template>
  <div class="chat_talk">
    <div class="talk_top">
      <template v-if="agoraRoom">
        <div class="top_img">
          <img v-if="agoraRoom.member.avatarUrl" :src="agoraRoom.member.avatarUrl" alt="" />
          <img v-else src="@/assets/img/empty.png" alt="" />
        </div>
        <div class="top_info">
          <p>{{ agoraRoom.member.name || agoraRoom.member.nickName || "" }}</p>
          <p class="tit">{{ agoraRoom.member.company }}</p>
        </div>
      </template>
      <i @click="talkClose" class="close iconfont icon-guanbi"></i>
    </div>
    <div class="talk_msg chat" ref="chat" @touchmove.prevent>
      <div class="f-tac loading" v-if="loading">Loading...</div>
      <div class="chat_wrap" id="chat_wrap">
        <div class="chat_item" v-for="item in barrageList" :key="item.id">
          <!-- 对方 -->
          <template v-if="item.messageType == 'text'">
            <div class="txt_left" v-if="item.isUser">
              <div class="txt" v-html="item.message.content"></div>
            </div>
            <div class="txt_right" v-else>
              <div class="txt" v-html="item.message.content"></div>
            </div>
          </template>
          <div class="txt_right" v-if="item.messageType == 'url'">
            <div class="txt room_info" @click.stop="joinVideoRoom(item)">
              <img :src="item.message.logo" alt="" />
              <div class="comp_info">
                <p class="info_title">Video chat room</p>
                <p class="info_txt">
                  {{ item.message.summary }}
                </p>
              </div>
            </div>
          </div>
          <div class="time" v-if="item.messageType == 'time'">
            {{ item.timeText }}
          </div>
          <div class="video_apply" v-if="item.messageType == 'request'">
            <p>
              {{ agoraRoom.member.name || agoraRoom.member.nickName }} is applying for video chat<span v-if="item.state != 'expire'" @click="videoConnClick(item)">Accept</span
              ><span style="color:#bbb" v-else>Accept</span>
            </p>
            <!-- <p>{{agoraRoom.member.name||agoraRoom.member.nickName}}向你发起了视频洽谈申请</p> -->
          </div>
          <div class="video_apply" v-if="item.messageType == 'roomClose'">
            <p>Sorry, the video chat room is closed</p>
          </div>
        </div>
      </div>
    </div>
    <div class="talk_bottom">
      <Spin fix v-if="userId && exhibitorId && !companyUserId">
        <!-- <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon> -->
        <div>You're not a customer service</div>
      </Spin>
      <div class="newBarrage" v-if="newBarrageList.length > 0" @click="getNewBarrage">
        <i class="iconfont icon-qipao"
          ><span>{{ newBarrageList.length }}</span></i
        >
      </div>
      <div class="bottom_btn">
        <i class="iconfont icon-shipin" @click="videoConnClick(false)" v-if="companyUserId" title="video call"></i>
        <i class="iconfont icon-jinzhishipin1" v-else title="not video call"></i>
      </div>
      <div class="input_con">
        <Input
          autofocus
          class="input_text"
          :disabled="!exhibitorId"
          @touchmove.prevent
          @on-enter="send()"
          v-model="con"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 3 }"
          placeholder="Please enter the content"
        />
      </div>
      <div class="send_wrap">
        <div class="send ok" v-if="con" @click="send">Send</div>
        <div class="send" v-else>Send</div>
      </div>
    </div>
    <Spin size="large" fix v-if="spinShow">
      <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
      <div>Loading</div>
    </Spin>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Bscroll from "better-scroll";
import moment from "moment";
import AgoraRTM from "agora-rtm-sdk";
import dateString from "../../assets/js/dateString";
import url from "@/utils/url";

// Bscroll.use(MouseWheel)
export default {
  name: "chat-talk",
  props: {
    exhibitorId: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      url: url,
      con: "",
      loading: false,
      scroll: null,
      isBottom: true,
      isTouch: false,
      client: null,
      barrageList: [], //接受留言列表
      newBarrageList: [],
      pageIndex: 1,
      pageSize: 20,
      finished: false,
      spinShow: false,
      agoraRoom: null,
      companyUserId: "",
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
      getShare: "getShare",
      token: "getToken",
    }),
    msgQuery() {
      return `
        query($query:QueryInput!){
          agoraMessageQuery{
            query(query:$query){
              hasNextPage
              indexFrom
              totalCount
              pageIndex
              items{
                createAt
                exhibitorId
                id
                message
                messageType
                publisherId
                state
                stateAt
                userId
              }
            }
          }
        }`;
    },
  },
  watch: {
    async userId(val, oldVal) {
      if (oldVal && this.client) {
        await this.invokingLogout();
      }
      this.con = "";
      this.loading = false;
      this.isBottom = true;
      this.isTouch = false;
      this.barrageList = [];
      this.newBarrageList = [];
      this.pageIndex = 1;
      this.pageSize = 20;
      this.finished = false;
      this.spinShow = false;
      this.agoraRoom = null;
      this.client = null;
      this.companyUserId = "";
      // Object.assign(this.$data, this.$options.data())
      this.init();
    },
  },
  created() {
    if (!this.exhibitorId) {
      return;
    }
    this.init();
  },
  // destroyed(){
  //    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  // },
  async beforeDestroy() {
    console.log("客服退出", this.client);
    if (this.client) {
      await this.invokingLogout();
    }
  },
  mounted() {
    // window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    this.initSwiper();
  },
  methods: {
    ...mapActions({
      unverifiedDialog: "unverifiedDialog",
      graphqlPostByZXS: "graphqlPostByZXS",
      agoraNotify: "talk/agoraNotify",
      addAgoraMessage: "talk/addAgoraMessage",
      getMessageToken: "talk/getMessageToken",
      upDateStateAgoraMessage: "talk/upDateStateAgoraMessage",
      graphqlPost: "graphqlPost",
      readUserMsg: "talk/readUserMsg",
    }),
    invokingLogout() {
      return new Promise((resolve, reject) => {
        this.client
          .logout()
          .then(() => {
            resolve(true);
            //  console.log('退出成功')
          })
          .catch((err) => {
            resolve(false);
          });
      });
    },
    init() {
      this.spinShow = true;
      if (this.userInfo && this.userInfo.inMember && this.userInfo.inMember.companyUsers && this.userInfo.inMember.companyUsers.id) {
        this.companyUserId = this.userInfo.inMember.companyUsers.id;
      } else {
        this.companyUserId = "";
        this.spinShow = false;
        return;
      }
      this.getAgoraRoom();
    },
    initSwiper() {
      this.scroll = new Bscroll(this.$refs.chat, {
        scrollY: true,
        click: true,
        probeType: 3,
        mouseWheel: true,
        pullDownRefresh: {
          threshold: 20,
          stop: 20,
        },
        bounce: {
          top: true,
          bottom: true,
        },
        // scrollbar: {
        //   fade: false,
        // },
      });
      let that = this;
      //用户轻抚
      this.scroll.on("flick", () => {
        let barAllH = $(".chat_wrap").height();
        let wrapH = $(".chat").height();
        if (barAllH > wrapH) {
          that.isTouch = true;
        }
      });
      // this.scroll.on("pullingUp",()=>{
      //   console.log('鼠标滚轮下拉触发',that.finished)
      //   if(that.finished){
      //     that.scroll.finishPullDown()
      //   }else{
      //     that.pageIndex+=1;
      //     that.loading=true;
      //     that.getMssage();
      //   }
      // })

      this.scroll.on("pullingDown", () => {
        console.log("下拉触发", that.finished);
        if (that.finished) {
          that.scroll.finishPullDown();
        } else {
          that.pageIndex += 1;
          that.loading = true;
          that.getMssage();
        }
      });
      //滚动中
      this.scroll.on("scroll", (obj) => {
        // if($('.barrage .bscroll-vertical-scrollbar .bscroll-indicator')){
        //    $('.barrage .bscroll-vertical-scrollbar .bscroll-indicator').addClass('scrollstyle');//
        // }
        let barAllH = $(".chat_wrap").height();
        // console.log( $(".chat_wrap"),'sss')
        let chatH = $(".chat").height();
        // console.log(barAllH,chatH)
        // console.log(barAllH - chatH,Math.abs(obj.y),'滚动中haodu')  // + 25
        if (barAllH - chatH <= Math.abs(obj.y) + 30) {
          that.isBottom = true;
          that.isTouch = false;
          if (that.newBarrageList.length) {
            that.getNewBarrage();
            // that.dataPush(model);
            // }
          }
        } else if (barAllH - chatH > 0) {
          that.isBottom = false;
        }
      });
    },
    async beforeunloadFn(e) {
      if (this.client) {
        await this.invokingLogout();
      }
    },
    //获取聊天室
    async getAgoraRoom() {
      let query = `
        query{
          agoraQuery{
            by(exhibitorId:"${this.exhibitorId}",userId:"${this.userId}"){
              id
              member{
                avatarUrl
                userId
                name
                nickName
                company
              }
              exhibitor{
                company{
                  name
                  logo
                  nameEn
                }
              }
            }
          }
        }
      `;
      let opt = {
        query: query,
        variables: {},
      };
      let data = await this.graphqlPostByZXS(opt);
      let res = JSON.parse(JSON.stringify(data.data.agoraQuery.by));
      if (!res.id) {
        this.$Message.error("There is an error in the chat room, please re-enter");
        return;
      }
      this.agoraRoom = res;
      // console.log(res,'聊天室');
      // let query1=`
      //   query{
      //     agoraQuery{
      //       isLog(agoraId:"${this.agoraRoom.id}")
      //     }
      //   }
      // `
      // let opt1={
      //   query:query1,
      //   variables:{},
      // }
      // let data1=await this.graphqlPostByZXS(opt1);
      // let res1=JSON.parse(JSON.stringify(data1.data.agoraQuery.isLog));
      // this.logState=res1;
      // console.log(this.logState,'lotW5Q5w')
      this.getMssage(true);
    },
    //获取消息记录
    async getMssage(isCreate = false) {
      let where = {
        Equal: {
          n: "userId",
          v: this.userId,
        },
        EqualA: {
          n: "exhibitorId",
          v: this.exhibitorId,
        },
      };
      let order = [
        {
          N: "createAt",
          v: 0,
        },
      ];
      let opt = {
        query: this.msgQuery,
        variables: {
          query: {
            page: this.pageIndex,
            size: this.pageSize,
            where: JSON.stringify(where),
            order: JSON.stringify(order),
          },
        },
      };
      let data = await this.graphqlPostByZXS(opt);
      let res = JSON.parse(JSON.stringify(data.data.agoraMessageQuery.query));
      // console.log(data.data.agoraMessageQuery.query,'resss');
      // let unRead=[];
      res.items.reverse();
      res.items.forEach((el) => {
        el.message = JSON.parse(el.message);
        el.isUser = el.publisherId == el.userId;
        // if(el.isUser){
        //   console.log(el.state,'消息的状态')
        //   if(el.state=='unread'){
        //     unRead.push(el.id);
        //   }
        // }
        el.timeStr = dateString.getDataString(moment(el.createAt).format("x"), true);
        // let time=moment().from(el.createAt);
        // // let nowTime=moment(new Date()).diff(el.createAt,'minute');
        // let timeMoment=moment(el.createAt).calendar(moment(), {
        //     sameDay: 'HH:mm',
        //     lastDay: '[昨天] HH:mm',
        //     lastWeek: '[上周]dd',
        //     sameElse: 'YYYY/MM/DD',
        //     // sameElse:(now)=>{
        //     //   console.log(now,'其他时间')
        //     // }
        // });
        // el.timeStr=timeMoment;
      });
      // console.log(unRead,'unReadArr')
      // if(unRead.length){
      this.setRead();
      // }
      let arr = this.addDateList(res.items);
      this.barrageList = [...arr, ...this.barrageList];
      console.log(this.barrageList, "ssss");
      if (isCreate) {
        this.initClinet();
        this.$nextTick(() => {
          this.getNewBarrage();
        });
      } else {
        this.loading = false;
        this.scroll.finishPullDown();
        this.$nextTick(() => {
          this.scroll.refresh();
        });
      }
      if (!res.hasNextPage) {
        this.finished = true;
      }
    },
    //进行时间添加
    addDateList(barrageList) {
      let arr = [];
      // console.log(barrageList,'barrList')
      for (let i = 0; i < barrageList.length; i++) {
        let e = barrageList[i];
        let e1 = barrageList[i + 1];

        // console.log(e,e1,i,'e1e1e1')
        if (e.messageType == "time") {
          arr.push(e);
          continue;
        }
        if (i == 0) {
          let mode = {
            timeText: e.timeStr,
            messageType: "time",
            id: this.getUUId(),
          };
          arr.push(mode);
        }
        if (e1) {
          let min = Math.abs(moment(e.createAt).diff(e1.createAt, "minute"));
          // console.log(min,e.createAt,e1.createAt,'miniminin')
          if (min >= 10) {
            let mode = {
              timeText: e1.timeStr,
              messageType: "time",
              id: this.getUUId(),
            };
            arr.push(e);
            if (i != 1) {
              arr.push(mode);
            }
            continue;
          }
        }
        // else{
        //   e1=barrageList[i-1];
        //   console.log(e,e1,i,'最后一个');
        //   let min=Math.abs(moment(e.createAt).diff(e1.createAt,'minute'));
        //   if(min>=10){
        //     let mode={
        //       timeText:e.timeStr,
        //       messageType:'time'
        //     }
        //     // arr.push(mode);
        //     arr.push(e);
        //     continue
        //   }
        // }
        arr.push(e);
        // console.log(barrageList[i].createAt,'createAt');
      }
      // this.barrageList=JSON.parse(JSON.stringify(arr));
      // console.log(arr,'arrrrr')
      return arr;
    },
    //初始化链接
    async initClinet() {
      //要token db992c4127c6454f9229216503d3e839         不要token    d805985473ad41d99c13d7adb6aa5648
      this.client = AgoraRTM.createInstance("db992c4127c6454f9229216503d3e839");
      let opt = {
        channelName: this.agoraRoom.id, //频道名称
        userId: "",
        privileges: [1000],
      };
      let token = await this.getMessageToken(opt); //获取token
      console.log(token, "token", "gooo");
      this.monitor();
      this.client
        .login({ token: token, uid: this.agoraRoom.id })
        .then(() => {
          this.spinShow = false;
          console.log("登陆成功");
          this.client
            .subscribePeersOnlineStatus([this.userId])
            .then(() => {
              console.log("订阅成功");
            })
            .catch((err) => {
              console.log(err, "订阅失败");
            });
        })
        .catch((err) => {
          this.$Message.warning("Login error");
          console.log(err, "登陆失败");
        });
    },
    monitor() {
      this.client.on("ConnectionStateChanged", (newState, reason) => {
        console.log("状态：" + newState + "; 原因：" + reason);
      });
      this.client.on("MessageFromPeer", ({ text }, peerId) => {
        if (text) {
          text = JSON.parse(text);
          console.log(text, "eeeeuser");
          if (text.userId == this.userId) {
            text.message = JSON.parse(text.message);
            text.isUser = true;
            if (text.state == "unread") {
              this.setRead();
            }
            this.dataPush(text);
          }
        }
        // text 为消息文本，peerId 是消息发送方 User ID
        // console.log(text,'客服得消息接收')
        // console.log(peerId,'发送方id-客服')
        /* 收到点对点消息的处理逻辑 */
      });
      this.client.on("PeersOnlineStatusChanged", (status) => {
        // if(status[this.agoraRoom.id]=='ONLINE'){
        //   this.isVideoConn=true;
        // }else{
        //   this.isVideoConn=false;
        // }
        console.log(status[this.userId], "状态 订阅用户状态");
      });
    },
    setRead() {
      let opt = {
        exhibitorId: this.exhibitorId,
        userId: this.userId,
      };
      this.readUserMsg(opt);
      // this.upDateStateAgoraMessage(stateOpt);
    },
    //发送视频连接
    async videoConnClick(e = false) {
      // console.log(this.isHasService,'是否有客服')
      if (!this.companyUserId) {
        this.$Message.warning("No permission");
        return;
      }
      let msgRequst = {
        url: `https://${this.url.mobileEn}/video-talk?agoraId=${this.agoraRoom.id}`, //请求类型
        logo: this.agoraRoom.exhibitor.company.logo,
        summary: this.agoraRoom.exhibitor.company.nameEn + "invites you to enter a video chat room...", //文字（动态不用传，如用户名等）
      };
      let req = {
        publisherId: this.companyUserId,
        exhibitorId: this.exhibitorId,
        userId: this.userId,
        messageType: "url",
        message: JSON.stringify(msgRequst),
      };
      console.log(req, "resssssss适配");
      let res = await this.addAgoraMessage(req);
      let opt = req;
      if (res && res.result) {
        console.log(e, "eeee");
        if (e) {
          let stateOpt = {
            messageIds: [e.id],
            state: "expire",
          };
          let stateRes = await this.upDateStateAgoraMessage(stateOpt);
          if (stateRes && stateRes.result) {
            this.barrageList.map((el) => {
              if (el.id == e.id) {
                el.state = "expire";
              }
            });
          }
        }
        opt = JSON.parse(JSON.stringify(res.data));
        this.client
        .sendMessageToPeer(
          { text: JSON.stringify(opt) }, // 符合 RtmMessage 接口的参数对象
          this.userId // 远端用户 ID
        )
        .then((sendResult) => {
          //发送成功->往接口里面发数据
          // console.log(sendResult,'sendR');
          //发送成功之后 ----------进入视频聊天界面
          opt.message = JSON.parse(opt.message);
          opt.isUser = false;
          this.dataPush(opt);
          if (sendResult.hasPeerReceived) {
            /* 远端用户收到消息的处理逻辑 说明在线 */
          } else {
            /* 服务器已接收、但远端用户不可达的处理逻辑 离线 */
          }
        })
        .catch((error) => {
          this.$Message.warning("Failed to send");
          console.log(error, "发送失败");
          /* 发送失败的处理逻辑 */
        });
        //发送成功
      } else {
        this.$Message.warning("Failed to send");
        return;
      }
      
    },
    //发送
    async send() {
      let str = JSON.parse(JSON.stringify(this.con));
      if (!this.con) {
        return;
      } else if (!str.replace(/ /g, "")) {
        this.$Message.warning("Sending blank messages is not allowed");
        return;
      }
      let req = {
        publisherId: this.companyUserId,
        exhibitorId: this.exhibitorId,
        userId: this.userId,
        messageType: "text",
        message: JSON.stringify({ content: this.con }),
      };
      let res = await this.addAgoraMessage(req);
      let opt = req;
      if (res && res.result) {
        opt = JSON.parse(JSON.stringify(res.data));
        this.client
          .sendMessageToPeer(
            { text: JSON.stringify(opt) }, // 符合 RtmMessage 接口的参数对象
            this.userId // 远端用户 ID
          )
          .then((sendResult) => {
            //发送成功->往接口里面发数据
            opt.message = JSON.parse(opt.message);
            opt.isUser = false;
            this.dataPush(opt);
            if (sendResult.hasPeerReceived) {
              /* 远端用户收到消息的处理逻辑 说明在线 */
            } else {
              /* 服务器已接收、但远端用户不可达的处理逻辑 离线 */
            }
          })
          .catch((error) => {
            this.$Message.error("Failed to send");
            console.log(error, "client 发送失败");
            /* 发送失败的处理逻辑 */
          });
        // if(!this.isInform&&this.logState=='not'){
        //   let op={
        //     exhibitorId:this.exhibitorId,
        //     userId:this.userId,
        //     langVersion:'zh-cn'
        //   }
        //   this.agoraNotify(op);
        //   // let res=await this.agoraNotify(op);
        //   // console.log(res,'ressss')
        //   if(res&&res.result){
        //     this.isInform=true;
        //   }
        // }
        this.con = "";
        //发送成功
      } else {
        
        this.$Message.error(res.message=='今日洽谈次数已用完'?'You have reached the maximum number of chats':res.message||"Failed to send");
      }
      
      // let mode = {
      //   id: this.getUUId(),
      //   txt: this.con.replace(/ /g,"&nbsp;"),
      //   logo:
      //     "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3516796069,1638897945&fm=15&gp=0.jpg",
      //   type: Math.random() >= 0.5 ? "from" : "me",
      // };
      // this.isBottom = true;
      // this.isTouch = false;
      // this.dataPush(mode);
    },

    //加入视频洽谈间
    joinVideoRoom(item) {
      //判断发起人-发起客服是否还在聊天室-->聊天室是否关闭 关闭push提示 没关闭进入
      // return
      if (item.state != "expire") {
        let opt = {
          msgId: item.id,
          agoraId: this.agoraRoom.id,
          type: "service",
        };
        if (item.publisherId == this.companyUserId) {
          opt.isInitiator = true;
        }
        let url = `https://${this.url.mobileEn}/video-talk?msgId=${opt.msgId}&agoraId=${opt.agoraId}&type=${opt.type}`;
        if (opt.isInitiator) {
          url += `&isInitiator=true`;
        }
        window.open(url);
        // this.LinkVideoTalk(opt)
      } else {
        let opt = {
          publisherId: this.userId,
          exhibitorId: this.exhibitorId,
          userId: this.userId,
          messageType: "roomClose",
          message: "",
          createAt: moment().format("YYYY/MM/DD HH:mm:ss"),
        };
        //提示不用存入数据库
        this.dataPush(opt);
      }
    },

    getUUId() {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
    },
    //设置滚动条滚动至底
    setScrollTop() {
      let Lis = $(".chat_item"); // 查找所有bar_block元素
      this.scroll.refresh();
      if (Lis && Lis.length) {
        this.scroll.scrollToElement(Lis[Lis.length - 1], 300); // 滑动到指定元素,设置动画300ms
      }
    },
    //数据存入
    dataPush(data) {
      let min = 0;
      if (this.barrageList.length) {
        if (this.barrageList[this.barrageList.length - 1].messageType == "roomClose" || data.messageType == "roomClose") {
          min = 0;
        } else {
          min = Math.abs(moment(data.createAt).diff(this.barrageList[this.barrageList.length - 1].createAt, "minute"));
        }
      } else {
        min = 10;
      }
      if (min >= 10) {
        data.timeStr = dateString.getDataString(moment(data.createAt).format("x"), true);
        let mode = {
          timeText: data.timeStr,
          messageType: "time",
          id: this.getUUId(),
        };
        this.barrageList.push(mode);
      }
      this.barrageList.push(data);
      this.$nextTick(() => {
        this.scroll.finishPullDown();
      });
      if (this.isBottom && !this.isTouch) {
        //
        // if(this.barrageList.length>20){    dom多了处理
        //    this.stortBarrageList.push(this.barrageList.shift());
        // }
        this.$nextTick(() => {
          this.setScrollTop();
        });
      } else {
        // this.newBarrageList.push(data);
        this.$set(this.newBarrageList, this.newBarrageList.length, data);
      }
      //
    },
    //读取新消息
    getNewBarrage() {
      this.newBarrageList = [];
      this.isBottom = true;
      this.$nextTick(() => {
        this.setScrollTop();
      });
    },
    talkClose() {
      this.$Bus.$emit("talkClose");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.chat_talk {
  width: 400px;
  height: 100%;
  position: relative;
  border-radius: 0 5px 5px 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 2;
  &::v-deep .ivu-spin {
    @include font_color(#1890ff);
    .ivu-spin-main {
      .ivu-spin-text {
        i {
          @include font_color(#1890ff);
        }
      }
    }
  }
  .talk_top {
    flex-shrink: 0;
    width: 100%;
    height: 62px;
    @include background_color(#1890ff);
    display: flex;
    align-items: center;
    padding: 10px;
    .top_img {
      flex-shrink: 0;
      width: 42px;
      height: 42px;
      margin-right: 10px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .top_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 14px;
        color: #fff;
        &.tit {
          font-size: 12px;
        }
      }
    }
    .close {
      flex-shrink: 0;
      font-size: 14px;
      color: #fff;
      margin-left: auto;
      margin-right: 14px;
      cursor: pointer;
    }
  }
  .talk_msg {
    flex-shrink: 0;
    height: 315px;
    width: 100%;
    background: #f5f8fa;
    position: relative;
    // overflow-y: auto;
    overflow: hidden;
    // -ms-overflow-style: none;
    // overflow: -moz-scrollbars-none;

    .chat_wrap {
      // height: 100%;
      padding: 0 14px;
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 10px;
      }
      &::-webkit-scrollbar {
        width: 6px;
        // opacity: 0;
      }
      &::-webkit-scrollbar-track {
        background: rgba(#000, 0);
        border-radius: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba($color: #000000, $alpha: 0.1);
        border-radius: 3px;
      }
      .chat_item {
        display: flex;
        width: 100%;
      }
      .txt {
        min-height: 32px;
        display: flex;
        align-items: center;
        font-size: 14px;
        max-width: 246px;
        background-color: #ffffff;
        padding: 5px 10px;
        &.room_info {
          width: 246px;
          padding: 10px 15px 10px 10px;
          align-items: initial;
          user-select: none;
          cursor: pointer;
          img {
            width: 64px;
            height: 64px;
            border-radius: 10px;
            margin-right: 10px;
            flex-shrink: 0;
            display: block;
          }
          .comp_info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: calc(100% - 74px);
            .info_title {
              color: #333;
              font-size: 16px;
            }
            .info_txt {
              margin-top: 5px;
              color: #666;
              font-size: 12px;
            }
          }
        }
      }
      .txt_left {
        .txt {
          border-radius: 0px 5px 5px 5px;
        }
      }
      .txt_right {
        .txt {
          border-radius: 5px 0px 5px 5px;
        }
      }
      .txt_left,
      .txt_right {
        margin-top: 20px;
        &.txt_right {
          margin-left: auto;
        }
      }
      //时间
      .time {
        display: flex;
        margin: 20px auto 0;
        align-items: center;
        height: 24px;
        padding: 0 10px;
        background: #fff;
        border-radius: 5px;
        @include font_color(#1890ff);
        box-shadow: 0px 5px 10px 0px rgba(91, 139, 237, 0.1);
        font-size: 12px;
      }
      .video_apply {
        display: flex;
        margin: 20px auto 0;
        align-items: center;
        height: 34px;
        padding: 0 30px;
        background: #fff;
        box-shadow: 0px 5px 10px 0px rgba(91, 139, 237, 0.1);
        border-radius: 5px;
        p {
          color: #333;
          font-size: 12px;
          span {
            margin-left: 5px;
            font-size: 12px;
            color: #1890ff;
          }
        }
      }
    }
  }
  .talk_bottom {
    height: 123px;
    width: 400px;
    background: #fff;
    border-top: 1px solid #e6e6e6;
    padding: 0 12px;
    position: relative;
    .newBarrage {
      position: absolute;
      right: 15px;
      top: -34px;
      // cursor: pointer;
      user-select: none;
      i {
        font-size: 22px;
        //  color: #1890ff;
        @include font_color(#1890ff);
        position: relative;
        span {
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 14px;
          transform: translate(-50%, -50%);
        }
      }
    }
    .bottom_btn {
      height: 25px;
      i {
        font-size: 18px;
        cursor: pointer;
      }
    }
    .input_con {
      width: 100%;
      .input_text {
        &::v-deep .ivu-input {
          padding: 0;
          border: none;
          border-radius: 0;
          box-shadow: none;
          &[disabled] {
            background: #fff;
            color: #999;
          }
          &::-webkit-scrollbar {
            width: 6px;
            // opacity: 0;
          }
          &::-webkit-scrollbar-track {
            background: rgba(#000, 0);
            border-radius: 2px;
          }
          &::-webkit-scrollbar-thumb {
            background: rgba($color: #000000, $alpha: 0.1);
            border-radius: 3px;
          }
        }
      }
    }
    .send_wrap {
      width: 100%;
      display: flex;
      margin-top: 3px;
      .send {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 26px;
        background-color: #f5f5f5;
        border-radius: 5px;
        color: #666;
        font-size: 14px;
        &.ok {
          color: #fff;
          @include background_color(#1890ff);
        }
      }
    }
  }
  .f-tac {
    text-align: center;
  }
  .loading {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-top: 20px;
    // opacity: ;
    // overflow: hidden;
    // transition: height .3s;
    // &.open{

    // }
    // position: absolute;
    // width: 100%;
    // top: 0;
    // left: 0;
    img {
      width: 18px;
      height: 18px;
      display: inline-block;
      margin-right: 5px;
      animation: 1s loader-01 linear infinite;
    }
  }
  @keyframes loader-01 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
</style>
