<template>
  <div class="top-ex">
    <Row>
      <Col class="top" span="13">
      <div class="top-l">
        <div class="top-l-l">
          <a @click="$router.push(`/home/index/${exhibitionId}`)" class="top_logo" href="javascript:;">
            <img :src="exhibitionInfo.logo" v-if="exhibitionInfo && exhibitionInfo.logo" />
            <img src="@/assets/img/empty.png" v-else />
          </a>
          <div class="top-msg">
            <h2>
              <span class="ex-name">{{ exhibitionInfo.nameEn }}</span>
              <span class="ex-logo">
                {{ $t("lang.Official") }}
                <img src="@assets/img/vip.png" />
              </span>
            </h2>
            <div class="time_addr">
              <p>
                {{ exhibitionInfo.startData | moment("LL- ") }}
                {{ exhibitionInfo.endDate | moment("LL") }}<span>{{ exhibitionInfo.addressEn }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      </Col>
      <Col span="11">
      <div class="info-box">
        <swiper :options="swiperOption" class="info-swiper">
          <swiper-slide v-if="pcShowExhibitionUserCount">{{ numFormat(exhibitionInfo.userCount) }} professionals have visited </swiper-slide>
          <swiper-slide v-if="pcShowExhibitionViewCount">{{ numFormat(exhibitionInfo.viewCount) }} visits in total </swiper-slide>
        </swiper>
      </div>
      <div class="inp-box">
        <div class="inp-wrap">
          <div class="inp">
            <Input v-model="search" type="text" @keyup.enter.native="searchHandle">
            <iSelect slot="prepend" @on-change="selectHandle" style="width: 90px" class="select_search" v-model="type">
              <Option :key="'search-' + item.value" :value="item.value" v-for="item in arr">{{ item.label }}</Option>
            </iSelect>
            <Button slot="append" type="primary" @click="searchHandle">{{ $t("lang.Search") }}</Button>
            </Input>
          </div>

          <!-- <div class="inp">
              <div class="line"></div>
              <iSelect
                @on-change="selectHandle"
                style="width: 74px"
                v-model="type"
              >
                <Option
                  :key="'search-' + item.value"
                  :value="item.value"
                  v-for="item in arr"
                  >{{ item.label }}</Option
                >
              </iSelect>
              <Input
                @on-search="searchHandle"
                :enter-button="$t('lang.Search')"
                search
                style="width: 350px;height:36px;"
                v-model="search"
              />
            </div> -->

          <!-- <div class="search-info">
              <div :class="{ active: item === search }" :key="'se-' + index" @click="search = item" class="item" v-for="(item, index) in history">
                {{ item }}
              </div>
            </div> -->
        </div>
        <Button @click="addNeeds()" ghost style="margin-left: 14px; border-radius: 0" type="primary" v-if="enabledSupplyDemand">{{ $t("lang.Create_a_Supply_Demand") }}</Button>
      </div>
      </Col>
    </Row>
    <needsModel ref="needsModel"></needsModel>
  </div>
</template>
<script>
import moment from "moment";
import needsModel from "@components/needsModel";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "top-ex",
  components: {
    needsModel,
  },
  data() {
    return {
      history: [],
      searchType: "show.search.exhibitors.en",
      search: "",
      type: "2",
      localLocale: "",
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 4000,
        },
        direction: "vertical", //水平(horizontal)或垂直(vertical)
      },
    };
  },
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
      searchTxt: "getSearchTxt",
      Type: "getSearchType",
      exhibitionId: "getExhibitionId",
      userInfo: "getUser",
      login: "getLogin",
      exhibitionConfig: "getExhibitionConfig",
    }),
    isRegOk() {
      return (
        this.userInfo &&
        this.userInfo.inMember &&
        this.userInfo.inMember.userRole &&
        this.userInfo.inMember.userRole != "VISITOR" &&
        this.userInfo.inMember.userRole != "Visitor"
      );
    },
    arr() {
      return [
        {
          value: "1",
          label: this.$t("lang.Exhibitor"),
        },
        {
          value: "2",
          label: this.$t("lang.Product"),
        },
        // {
        //   value: "3",
        //   label: this.$t("lang.Activity"),
        // },
        // {
        //   value: "4",
        //   label: this.$t("lang.Supply"),
        // },
      ];
    },
    enabledSupplyDemand() {
      return (
        this.exhibitionConfig &&
        this.exhibitionConfig.feature &&
        this.exhibitionConfig.feature.enabledSupplyDemand
      );
    },
    pcShowExhibitionViewCount() {
      return (
        this.exhibitionConfig &&
        this.exhibitionConfig.feature &&
        this.exhibitionConfig.display.pcShowExhibitionViewCount
      );
    },
    pcShowExhibitionUserCount() {
      return (
        this.exhibitionConfig &&
        this.exhibitionConfig.feature &&
        this.exhibitionConfig.display.pcShowExhibitionUserCount
      );
    },
  },
  watch: {
    $route(to, form) {
      let url = "";
      if (this.type === "1") {
        url = "/home/exhibitor";
      }
      if (this.type === "2") {
        url = "/home/product";
      }
      if (this.type === "3") {
        url = "/home/activity";
      }
      if (this.type === "4") {
        url = "/home/demand";
      }
      if (location.pathname.indexOf(url) === -1) {
        this.setSearchTxt("");
      }
    },
  },
  created() {
    this.init();
    this.search = "";
    this.setSearchTxt("");
    this.type = this.Type || "2";
  },
  methods: {
    ...mapMutations({
      setSearchTxt: "setSearchTxt",
      setSearchType: "setSearchType",
    }),
    ...mapActions({
      addSearch: "search/addSearch",
      getSearch: "search/getSearch",
      unverifiedDialog: "unverifiedDialog",
    }),
    async init() {
      this.getHistroy();
      moment.locale("en"); // 默认的语言环境为英语。
      this.localLocale = moment();
    },
    selectHandle(e) {
      if (e === "1") {
        this.searchType = "show.search.exhibitors.en";
      }
      if (e === "2") {
        this.searchType = "show.search.products.en";
      }
      if (e === "3") {
        this.searchType = "show.search.activities.en";
      }
      if (e === "4") {
        this.searchType = "show.search.supply-demand.en";
      }
      this.setSearchType(e);
      this.getHistroy();
      this.search = "";
      this.setSearchTxt("");
    },
    async getHistroy() {
      const bool = await this.getSearch({
        exhibitionId: this.exhibitionId,
        origin: this.searchType,
      });
      if (bool && bool.searchWordQuery) {
        this.history = bool.searchWordQuery.tops;
      }
    },
    addNeeds() {
      if (!this.login || !this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      if (this.userInfo && this.userInfo.inMember.isBlacklist) {
        this.$Message.warning("You do not have access");
        return;
      }
      this.$refs.needsModel.open();
    },
    async searchHandle() {
      this.setSearchTxt(this.search);
      if (!this.search) return;
      // show.search.exhibitors 展商搜索
      // show.search.products 展品搜索
      // show.search.activities 活动搜索
      // show.search.supply-demand 供需搜索
      let url = "";
      let bool = false; // 当前页面也是所选搜索的路由
      if (this.type === "1") {
        url = "/home/exhibitor";
        this.searchType = "show.search.exhibitors.en";
        bool = window.location.pathname.includes(url);
      }
      if (this.type === "2") {
        url = "/home/product";
        this.searchType = "show.search.products.en";
        bool = window.location.pathname.includes(url);
      }
      if (this.type === "3") {
        url = "/home/activity";
        this.searchType = "show.search.activities.en";
        bool = window.location.pathname.includes(url);
      }
      if (this.type === "4") {
        url = "/home/demand";
        this.searchType = "show.search.supply-demand.en";
        bool = window.location.pathname.includes(url);
      }
      const opt = {
        itemId: this.exhibitionId,
        origin: this.searchType,
        word: this.search,
      };

      await this.addSearch(opt);
      this.getHistroy();
      if (bool) {
        this.$router.push(`${url}/${this.exhibitionId}`);
      } else {
        this.$store.commit("setCateArrVuex", []);
        this.$store.commit("setRegionArrVuex", []);
        this.$store.commit("setRegionArrNameVuex", []);
        this.$router.push(`${url}/${this.exhibitionId}`);
      }
    },
    numFormat(n) {
      if (!n) {
        return 0;
      }
      let num = n.toString();
      let decimals = "";
      // 判断是否有小数
      num.indexOf(".") > -1 ? (decimals = num.split(".")[1]) : decimals;
      let len = num.length;
      if (len <= 3) {
        return num;
      } else {
        let temp = "";
        let remainder = len % 3;
        decimals ? (temp = "." + decimals) : temp;
        if (remainder > 0) {
          // 不是3的整数倍
          return (
            num.slice(0, remainder) +
            "," +
            num.slice(remainder, len).match(/\d{3}/g).join(",") +
            temp
          );
        } else {
          // 是3的整数倍
          return num.slice(0, len).match(/\d{3}/g).join(",") + temp;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.top-ex {
  width: 1200px;
  margin: 0 auto;
  height: 100px;
  margin-top: 18px;
  margin-bottom: 18px;
  ::v-deep {
    .ivu-input-search:before {
      opacity: 0;
    }
  }
  .top {
    &-msg {
      position: relative;
      padding: 0 16px 0 10px;
      border-left: 1px solid #e6e6e6;
      font-size: 12px;
      color: #333;
      width: calc(100% - 150px);
      h2 {
        font-size: 14px;
        color: #1a1a1a;
        display: flex;
        align-items: center;
        font-style: normal;
        margin-bottom: 6px;
        display: flex;
        align-items: flex-start;

        .ex-name {
          max-width: calc(100% - 100px);
          overflow: hidden;
          font-size: 18px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .ex-logo {
          @include font_color(#0190fe);
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
          max-width: 100px;
          display: flex;
          align-items: center;
          font-size: 18px;
          img {
            width: 18px;
            height: 18px;
            position: relative;
            top: -1px;
            left: 2px;
          }
        }
      }
      .time_addr {
        font-size: 16px;
        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          span {
            margin-left: 10px;
          }
        }
      }
    }
    &-l {
      &-l {
        display: flex;
        align-items: center;
        .top_logo {
          img {
            height: 100px;
            max-width: 150px;
          }
          margin-right: 10px;
        }
        img {
          vertical-align: middle;
          display: block;
        }
      }
    }
    &-r {
    }
  }
  .inp {
    width: 330px;
    ::v-deep {
      .ivu-input,
      .ivu-btn,
      .ivu-input-group-prepend,
      .ivu-input-group-append {
        border-radius: 0;
      }
      .ivu-input-group-prepend,
      .ivu-input-group-append {
        background: transparent;
      }
      .ivu-input-group-append {
        @include background_color(#1890ff);
        color: #fff;
      }
      .select_search {
        .ivu-select-selection {
          .ivu-select-selected-value {
            // color: #2d8cf0;
            @include font_color(#1890ff);
          }
        }
      }
    }
  }
}
.inp-box {
  display: flex;
  justify-content: flex-end;
}
.search-info {
  margin: 15px 0 26px;
  font-size: 12px;
  color: #666;

  .item {
    display: inline-block;
    max-width: 330px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    &.active {
      @include font_color(#1890ff);
    }
  }
}
.histroy {
  width: 266px;
  height: 154px;
  background-color: #fff;
  border: solid 1px #e0e0e0;
}
.info-box {
  height: 36px;
  line-height: 36px;
  @include font_color(#1890ff);
  font-size: 14px;
  text-align: right;
  padding-right: 202px;
  overflow: hidden;
  .info-swiper {
    height: 100%;
  }
}
</style>
